var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("Main Setting")])])])]),_c('CCardBody',[_c('div',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":false}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h5',[_vm._v("Ads Setting (Facebook)")]),_c('hr')]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Facebook Interestitial Id","vid":"facebook_interestitial_id","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"facebook_interestitial_id"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Facebook Interestitial Id ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"facebook_interestitial_id","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.facebook_interestitial_id),callback:function ($$v) {_vm.$set(_vm.formData, "facebook_interestitial_id", $$v)},expression:"formData.facebook_interestitial_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Facebook Banner","vid":"facebook_banner_id","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"facebook_banner_id"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Facebook Banner ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"facebook_banner_id","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.facebook_banner_id),callback:function ($$v) {_vm.$set(_vm.formData, "facebook_banner_id", $$v)},expression:"formData.facebook_banner_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Facebook Reward Video Ads","vid":"facebook_reward_video_ad","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"facebook_reward_video_ad"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Facebook Reward Video Ads ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"facebook_reward_video_ad","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.facebook_reward_video_ad),callback:function ($$v) {_vm.$set(_vm.formData, "facebook_reward_video_ad", $$v)},expression:"formData.facebook_reward_video_ad"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Facebook Notice Id","vid":"facebook_notice_id","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"facebook_notice_id"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Facebook Notice Id ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"facebook_notice_id","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.facebook_notice_id),callback:function ($$v) {_vm.$set(_vm.formData, "facebook_notice_id", $$v)},expression:"formData.facebook_notice_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Active Facebook Ads","vid":"is_facebook_ad","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"is_facebook_ad"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Active Facebook Ads ")]},proxy:true}],null,true)},[_c('b-form-checkbox',{attrs:{"size":"md","name":"check-button","switch":""},model:{value:(_vm.formData.is_facebook_ad),callback:function ($$v) {_vm.$set(_vm.formData, "is_facebook_ad", $$v)},expression:"formData.is_facebook_ad"}},[(_vm.formData.is_facebook_ad)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OF")])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12 text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h5',[_vm._v("Ads Setting")]),_c('hr')]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Add Banner","vid":"admob_banner","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"admob_banner"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Add Banner ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"admob_banner","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.admob_banner),callback:function ($$v) {_vm.$set(_vm.formData, "admob_banner", $$v)},expression:"formData.admob_banner"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Add Inter","vid":"admob_inter","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"admob_inter"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Add Inter ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"admob_inter","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.admob_inter),callback:function ($$v) {_vm.$set(_vm.formData, "admob_inter", $$v)},expression:"formData.admob_inter"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Add Native","vid":"admob_native","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"admob_native"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Add Native ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"admob_native","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.admob_native),callback:function ($$v) {_vm.$set(_vm.formData, "admob_native", $$v)},expression:"formData.admob_native"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"App ID","vid":"app_id","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"app_id"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" App ID ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"app_id","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.app_id),callback:function ($$v) {_vm.$set(_vm.formData, "app_id", $$v)},expression:"formData.app_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Show Ads","vid":"show_ads","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"show_ads"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Show Ads ")]},proxy:true}],null,true)},[_c('b-form-checkbox',{attrs:{"size":"md","name":"check-button","switch":""},model:{value:(_vm.formData.show_ads),callback:function ($$v) {_vm.$set(_vm.formData, "show_ads", $$v)},expression:"formData.show_ads"}},[(_vm.formData.show_ads)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OF")])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12 text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h5',[_vm._v("Custom Ads Setting")]),_c('hr')]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Add Text (Video Play)","vid":"ad_text_video_play","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"ad_text_video_play"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Add Text (Video Play) ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"ad_text_video_play","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.ad_text_video_play),callback:function ($$v) {_vm.$set(_vm.formData, "ad_text_video_play", $$v)},expression:"formData.ad_text_video_play"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"As Text Video Play URL","vid":"ad_text_video_play_url","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"ad_text_video_play_url"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" As Text Video Play URL ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"ad_text_video_play_url","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.ad_text_video_play_url),callback:function ($$v) {_vm.$set(_vm.formData, "ad_text_video_play_url", $$v)},expression:"formData.ad_text_video_play_url"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Show Ads","vid":"show_ads_custom","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"show_ads_custom"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Show Ads ")]},proxy:true}],null,true)},[_c('b-form-checkbox',{attrs:{"size":"md","name":"check-button","switch":""},model:{value:(_vm.formData.show_ads_custom),callback:function ($$v) {_vm.$set(_vm.formData, "show_ads_custom", $$v)},expression:"formData.show_ads_custom"}},[(_vm.formData.show_ads_custom)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OF")])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12 text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h5',[_vm._v("Tab Links Setting")]),_c('hr')]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Home Score Link","vid":"home_score_link","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"home_score_link"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Home Score Link ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"home_score_link","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.home_score_link),callback:function ($$v) {_vm.$set(_vm.formData, "home_score_link", $$v)},expression:"formData.home_score_link"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Live Score Link","vid":"live_score_link","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"live_score_link"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Live Score Link ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"live_score_link","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.live_score_link),callback:function ($$v) {_vm.$set(_vm.formData, "live_score_link", $$v)},expression:"formData.live_score_link"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Schedule Link","vid":"schedule_link","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"schedule_link"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Schedule Link ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"schedule_link","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.schedule_link),callback:function ($$v) {_vm.$set(_vm.formData, "schedule_link", $$v)},expression:"formData.schedule_link"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Update Link","vid":"update_link","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"update_link"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Update Link ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"update_link","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.update_link),callback:function ($$v) {_vm.$set(_vm.formData, "update_link", $$v)},expression:"formData.update_link"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12 text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h5',[_vm._v("Update And Notice Setting")]),_c('hr')]),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Update App Link","vid":"update_app_link","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"update_app_link"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Update App Link ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"update_app_link","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.update_app_link),callback:function ($$v) {_vm.$set(_vm.formData, "update_app_link", $$v)},expression:"formData.update_app_link"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Video PLay Notice","vid":"video_play_notice","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"video_play_notice"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Video PLay Notice ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"video_play_notice","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.video_play_notice),callback:function ($$v) {_vm.$set(_vm.formData, "video_play_notice", $$v)},expression:"formData.video_play_notice"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Show TV","vid":"show_tv","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"show_tv"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Show TV ")]},proxy:true}],null,true)},[_c('b-form-checkbox',{attrs:{"size":"md","name":"check-button","switch":""},model:{value:(_vm.formData.show_tv),callback:function ($$v) {_vm.$set(_vm.formData, "show_tv", $$v)},expression:"formData.show_tv"}},[(_vm.formData.show_tv)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OF")])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12 text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))])],1)])])]}}])})],1)],1)],1)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }